import React, { useState, useEffect } from "react";

export const CrazyCountdown = ({ nextTime }: { nextTime?: number }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const getTargetDate = () => {
      if (nextTime) return nextTime * 1000;
      const now = new Date();
      now.setHours(23, 59, 59);
      return now.getTime();
    };

    const calculateTimeLeft = () => {
      const difference = new Date(getTargetDate()).getTime() - new Date().getTime();
      
      if (difference <= 0) {
        return { hours: 0, minutes: 0, seconds: 0 };
      }

      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [nextTime]);

  return (
    <div>
      {Object.entries(timeLeft).map(([interval, value]) => (
        <span key={interval}>
          {String(value).padStart(2, "0")}
          {["hours", "minutes"].includes(interval) ? ":" : ""}
        </span>
      ))}
    </div>
  );
};

export const DailyCountdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      
      const difference = tomorrow.getTime() - new Date().getTime();
      
      if (difference <= 0) {
        return { hours: 0, minutes: 0 };
      }

      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60)
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {Object.keys(timeLeft).length ? (
        Object.entries(timeLeft).map(([interval, value]) => (
          <span key={interval}>
            {String(value).padStart(2, "0")}
            {interval === "hours" ? ":" : ""}
          </span>
        ))
      ) : (
        <span>00:00</span>
      )}
    </div>
  );
};