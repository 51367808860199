import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import './Start.css';

interface StartPageProps {
  updateView: (view: string) => void;
  userData: {
    language_code: string;
    egg: number;
  };
}

interface SlideContent {
  image: string;
  description: {
    ru: string;
    en: string;
  };
}

// Описания слайдов вынесены в константу для лучшей поддерживаемости
const SLIDES: SlideContent[] = [
  {
    image: 'first.webp',
    description: {
      ru: 'Это игра, в которой вы развиваете своего EGG, зарабатываете игровые монеты и, в конечном итоге, получаете настоящий токен с реальной ценностью на бирже. Более того, вы сможете зарабатывать уже существующую на бирже монету TON во время игры и использовать ее по своему усмотрению, не дожидаясь листинга игровой монеты EGG.',
      en: 'This is a game in which you develop your EGG, earn game coins and eventually get a real token with the exchange value. Moreover, you will be able to earn TON coin already existing on the exchange during the game and use it at your own request without waiting for the EGG game coin to be listed.'
    }
  },
  {
    image: 'second.webp',
    description: {
      ru: 'Основные способы заработка игровой монеты EGG включают: кликать по экрану, сражаться и прокачивать своего EGG.',
      en: 'The main ways to earn EGG in-game coin include clicking on the screen, battling and upgrading your EGG.'
    }
  },
  {
    image: 'third.webp',
    description: {
      ru: 'Вы можете прокачивать своего EGG, чтобы повысить пассивный почасовой доход и начать зарабатывать прямо во время игры',
      en: 'You can pump your EGG to increase your passive hourly income and start earning right while playing the game.'
    }
  },
  {
    image: 'fourth.webp',
    description: {
      ru: 'Приглашайте друзей присоединиться к игре, и вы будете зарабатывать процент от их доходов. Вам начисляется процент от ваших друзей до целых 10 уровней в глубину. Это позволит вам быстрее и эффективнее накапливать виртуальные монеты EGG.',
      en: 'Invite your friends to join the game and you will earn a percentage of their income. You get a share of credits from your friends up to as much as 10 levels. This allows you to accumulate virtual EGG coins faster and more efficiently.'
    }
  },
  {
    image: 'fifth.webp',
    description: {
      ru: 'Чем дальше вы продвигаетесь в игре, тем больше будет ваш эирдроп токенов EGG и тем больше вы заработаете токенов TON уже во время игры.',
      en: 'The further you progress in the game, the bigger your eirdrop of EGG tokens will be and the more tokens you will earn TON tokens during the game.'
    }
  },
  {
    image: 'sixth.webp',
    description: {
      ru: 'Внимательно следите за своим EGG, чтобы он не потерял свои силы и мощность, иначе ваш EGG будет утрачен и вам придется начать сначала.',
      en: 'Follow your EGG attentively so that it does not lose its strength and power, otherwise your EGG will be lost and you will have to start over.'
    }
  }
];

const StartPage: React.FC<StartPageProps> = ({ updateView, userData }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>(new Array(SLIDES.length).fill(false));
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  // Предзагрузка изображений
  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = SLIDES.map((slide, index) => {
        return new Promise<void>((resolve) => {
          const img = new Image();
          img.src = require(`../../static/start/${slide.image}`);
          img.onload = () => {
            setImagesLoaded(prev => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            resolve();
          };
        });
      });

      await Promise.all(imagePromises);
      setAllImagesLoaded(true);
    };

    loadImages();
  }, []);

  const handleNext = () => {
    if (currentScreen === SLIDES.length - 1) {
      updateView('EGG');
    } else {
      setCurrentScreen(prev => prev + 1);
    }
  };

  const handleSkip = () => {
    updateView('EGG');
  };

  // Мемоизация прогресс-бара
  const progressBar = useMemo(() => (
    <div className="progress_bar">
      <ul id="progressbar">
        {SLIDES.map((_, index) => (
          <li
            key={index}
            className={currentScreen === index ? 'active' : ''}
          />
        ))}
      </ul>
    </div>
  ), [currentScreen]);

  // Анимация для слайдов
  const slideVariants = {
    enter: { opacity: 0.2 },
    center: { opacity: 1 },
    exit: { opacity: 0 }
  };

  if (!allImagesLoaded) {
    return (
      <div className="start_elems loading">
        <div className="loading-spinner" />
      </div>
    );
  }

  return (
    <div className="start_elems">
        <motion.div
          key={currentScreen}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: "tween", duration: 0.5 }}
          className="start_content__block"
        >
          <div className={`start_pic pic_${currentScreen}`} />
          
          <div className="start_desc">
            {SLIDES[currentScreen].description[userData.language_code as keyof typeof SLIDES[0]['description']]}
          </div>
        </motion.div>
      <div className="start_footer">
        {progressBar}
      </div>

      <motion.div
        className="next_button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleNext}
      >
        {userData.language_code === 'ru' ? 'ДАЛЕЕ' : 'NEXT'}
      </motion.div>

      {userData.egg !== 0 && (
        <motion.div
          className="skip_button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleSkip}
        >
          {userData.language_code === 'ru' ? 'ПРОПУСТИТЬ' : 'SKIP'}
        </motion.div>
      )}
    </div>
  );
};

export default React.memo(StartPage);