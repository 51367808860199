import React, { useState, useCallback, useMemo, useEffect } from 'react';
import * as QRCodeReact from 'qrcode.react';
import { copyTextToClipboard, format } from '../utils';
import { TG } from "../../telegramContext";
import { api } from '../../App';
import './Friends.css';
import { BluePopup } from "../popups/popups_component"
import "./Popups.css"


// Constants
const INVITE_REWARDS = {
  regular: 7500,
  premium: 25000
};





interface ModalFriendsProps {
  type: string
  userData: any
  friends: number
  updateView: (view:string) => void
  onClose: () => void
}


export const ModalFriends = ({
  type = '',
  userData,
  friends = 0,
  updateView,
  onClose,
}: ModalFriendsProps) => {
  var header_content: any = ''
  var title: any = ''
  var content: any = ''
  var footer: any = ''
  
  if (type === '') {
    return <></>
  }

  var eggs_q_t = ''

  var eggs_to_buy = new Map()
  eggs_to_buy.set("1", {"price":"10"})
  eggs_to_buy.set("3", {"price":"20"})
  eggs_to_buy.set("5", {"price":"30"})
  eggs_to_buy.set("8", {"price":"50"})
  eggs_to_buy.set("10", {"price":"80"})

  function forwardToLink() {
    window.open(`https://t.me/share/url?url=https://t.me/egg_play_bot/app?startapp=${TG.WebApp.initDataUnsafe.user.id}`)
  }

  if (type === "friends") {
    if (friends === 0) {

      if (userData.language_code === "ru") {
        title = "СПИСОК ДРУЗЕЙ ПУСТ"
      } else {
        title = "FRIENDS LIST IS EMPTY"
      }

      content = <><div className="add_friend_elem">
      <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
      <div className='friends_actions_text_elem' onClick={() => forwardToLink()}>
        <div className='friends_actions_text'>
        {(userData.language_code === "ru") ?
            (<>ПРИГЛАСИТЬ ДРУГА</>)
            :
            (<>INVITE A FRIEND</>)
          }
        </div>
      </div>
    </div></>
    } else {
      if (userData.language_code === "ru") {
        title = "СПИСОК ДРУЗЕЙ"
      } else {
        title = "FRIENDS LIST"
      }
      var friends_content: any = []
      Object.entries(userData.friends).forEach(([k, v]:[string, any]) => {
        friends_content.push(<>
          <div className="friends_elem">
            <div className="friends_left_block">
              <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>

              {v["invited_users_count"]}
            </div>
            <div className="friends_center_block">
            {(userData.language_code === "ru") ?
              (<>{k} ЛИНИЯ</>)
              :
              (<>{k} LINE</>)}
            </div>
            <div className="friends_right_block">
              <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>

              {(v["total_earned_value"]) ? (`+${format(v["total_earned_value"])}`) : (0)}
            </div>
          </div>
        </>)
      });
      content = <>
        <div className="friends_elems">
          {friends_content.map((object:any, i:any) => <>{object}</>)}
        </div>
        </>
      
    }
  } else if (type === "buy_friends") {
    if (userData.language_code === "ru") {
      title = "КУПИТЬ EGG ДРУГА"
    } else {
      title = "BUY EGG FRIEND"
    }
    content = <>
    <div className="buy_friend_elems">

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_1")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            1 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 10
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_3")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            3 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 20
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_5")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            5 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 30
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_8")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            8 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 50
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_10")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            10 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 80
          </div>
        </div>
      </div>
    </div>
    </>
  } else if (type.includes("buy_friends_")) {

    var eggs_quantity: string = type.replace('buy_friends_', '')

    if (userData.language_code === "ru") {
      title = "КУПИТЬ EGG ДРУГА"
    } else {
      title = "BUY EGG FRIEND"
    }
    content = <>
    <div className="buy_friends_checkout">

      <div className="buy_friend_elem item">
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            {eggs_quantity} EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ {eggs_to_buy.get(eggs_quantity).price}
          </div>
        </div>
      </div>
      
      <div className="buy_friends_checkout_title">
      {(userData.language_code === "ru") ?
        ("ВЫБЕРИТЕ СПОСОБ ОПЛАТЫ")
        :
        ("CHOOSE PAYMENT METHOD")}
      </div>
      
      <div className="buy_friend_checkout_buttons">

        <div className="buy_friend_checkout_button">
          <div className="buy_friend_left">
            <div className="buy_friend_checkout_title">
              <div className="buy_friend_checkout_main">
              {(userData.language_code === "ru") ?
                ("Оплатить TON")
                :
                ("Pay with TON")}
              </div>
            </div>
          </div>
          <div className="buy_friend_right">
            <div className="buy_friend_checkout_pic ton">
              {/* <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg> */}
            </div>
          </div>
        </div>
        
        <div className="buy_friend_checkout_button">
          <div className="buy_friend_left">
            <div className="buy_friend_checkout_title">
              <div className="buy_friend_checkout_main">
              {(userData.language_code === "ru") ?
                ("Оплатить картой")
                :
                ("Pay by card")}
              </div>
              <div className="buy_friend_checkout_desc">
                Visa, Mastercard
              </div>
            </div>
          </div>
          <div className="buy_friend_right">
            {/* <div className="buy_friend_checkout_pic visa"> */}
              <svg className='buy_friend_checkout_pic visa' xmlns="http://www.w3.org/2000/svg"></svg>
            {/* </div> */}
            {/* <div className="buy_friend_checkout_pic mastercard"> */}
              <svg className='buy_friend_checkout_pic mastercard' xmlns="http://www.w3.org/2000/svg"></svg>
            {/* </div> */}
          </div>
        </div>

      </div>

    </div>
    </>

    footer = <><div className='buy_friend_footer'>
      <div className="buy_friend_back" onClick={() => updateView("buy_friends")}>
        {(userData.language_code === "ru") ?
          ("НАЗАД")
          :
          ("BACK")}
      </div>
    </div></>
  }

  return (
    <>
     <div className='modal' onClick={onClose}>
      {/* @ts-ignore */}
      <div className={(friends === 0) ? ('modalFriends-dialog empty '+type.match("buy_friends|friends")) : ('modalFriends-dialog '+type.match("buy_friends|friends"))} onClick={e => e.stopPropagation()}>
        <div className='modalFriends-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          {/* @ts-ignore */}
          <svg className={"modalFriends__img "+type.match("buy_friends|friends")} xmlns="http://www.w3.org/2000/svg"></svg>
          <div className='modalFriends-title'>{title}</div>
          {/* {title_button && <div className='modalFriends-title_button'>{title_button}</div>} */}
          {header_content && <div className='modalFriends-header_content'>{header_content}</div>}

        </div>
        <div className='modalFriends-body'>
          <div className='modalFriends-content'>{content}</div>
        </div>
        {footer && <div className='modalFriends-footer'>{footer}</div>}
      </div>
    </div>
    </>
  )

}
















interface FriendsPageProps {
  userData: any;
  updateView: (view: string) => void;
}

const FriendsPage: React.FC<FriendsPageProps> = ({ userData, updateView }) => {
  const [popup, setPopup] = useState({ visible: false, text: '' });
  const [screenView, setScreenView] = useState('');
  const [qrCodeSize, setQRCodeSize] = useState(100);
  const [qrCodePaddingTop, setQRCodePaddingTop] = useState("2vh");

  // Memoized values
  const inviteLink = useMemo(() => 
    `https://t.me/egg_play_bot/app?startapp=${TG.WebApp.initDataUnsafe.user.id}`, 
    [TG.WebApp.initDataUnsafe.user.id]
  );

  useEffect(() => {
    const updateQRCodeSize = () => {
      const screenHeight = window.innerHeight;
      const sizes = [
        { maxHeight: 600, size: 160, paddingTop: "2vh" },
        { maxHeight: 800, size: 180, paddingTop: "7vh" },
        { maxHeight: 1000, size: 200, paddingTop: "12vh" },
        { maxHeight: Infinity, size: 220, paddingTop: "15vh" }
      ];
      const selected = sizes.find(s => screenHeight <= s.maxHeight)
      
      const selectedSize = selected?.size || 200;
      const selectedPaddingTop = selected?.paddingTop || "2vh";
      setQRCodeSize(selectedSize);
      setQRCodePaddingTop(selectedPaddingTop)
    };
     updateQRCodeSize();
    // Слушатель изменения размера окна
    //  window.addEventListener('resize', updateQRCodeSize);
     return () => window.removeEventListener('resize', updateQRCodeSize);
   }, []);

  const totalFriends = useMemo(() => 
    Object.values(userData.friends).reduce((acc: number, line: any) => 
      acc + line.invited_users_count, 0
    ), [userData.friends]
  );

  // Handlers
  const handleShare = useCallback(() => {
    window.open(`https://t.me/share/url?url=${encodeURIComponent(inviteLink)}`, '_blank', 'noopener,noreferrer');
  }, [inviteLink]);

  const handleCopy = useCallback(() => {
    copyTextToClipboard(inviteLink);
    const text = userData.language_code === "ru" 
      ? "Ссылка-Приглашение скопирована"
      : "Invite-Link copied";
    setPopup({ visible: true, text });
    setTimeout(() => setPopup({ visible: false, text: '' }), 3000);
  }, [inviteLink, userData.language_code]);

  const showFriendsDetails = async () => {
    try {
      const res = await api.get('/api/user/get');
      setScreenView('friends');
      updateView('FRIENDS');
    } catch (err) {
      console.error('Error fetching friends data:', err);
      setScreenView('friends');
      updateView('FRIENDS');
    }
  };

  return (
    <>
    <div className="friends__block">
      {/* Top Section - Rewards */}
      <div className="friends_top_block">
        <div className="friends_reward_elems">
          {/* Regular Friend Invite Card */}
          <div className="friends_reward_elem friend">
            <div className="friends_reward_first_icon">
              <div className='icon'></div>
            </div>
            <div className='friends_reward_text_elem'>
              <div className='friends_reward_text'>
                {userData.language_code === "ru" ? 
                  <>Пригласите<br/>друга</> : 
                  <>Invite a friend</>}
              </div>
              <div className='friends_reward_price'>
                <div className='friends_reward_icon'>
                  <svg xmlns="http://www.w3.org/2000/svg"></svg>
                </div>
                <div className='friends_reward_price_value'>
                  + {format(INVITE_REWARDS.regular)}
                </div>
              </div>
            </div>
          </div>

          {/* Premium Friend Invite Card */}
          <div className="friends_reward_elem premium">
            <div className="friends_reward_first_icon">
              <div className='icon'></div>
            </div>
            <div className='friends_reward_text_elem'>
              <div className='friends_reward_text'>
                {userData.language_code === "ru" ? 
                  <>Пригласите друга<br/>с Telegram Premium</> : 
                  <>Invite a friend<br/>with Telegram Premium</>}
              </div>
              <div className='friends_reward_price'>
                <div className='friends_reward_icon'>
                  <svg xmlns="http://www.w3.org/2000/svg"></svg>
                </div>
                <div className='friends_reward_price_value'>
                  + {format(INVITE_REWARDS.premium)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="friends_actions_elems">
          <div className="friends_actions_elem first" onClick={handleShare}>
            <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            <div className='friends_actions_text_elem'>
              <div className='friends_actions_text'>
                {userData.language_code === "ru" ? 
                  "ПРИГЛАСИТЬ ДРУГА" : 
                  "INVITE A FRIEND"}
              </div>
            </div>
          </div>
          <div className="friends_actions_elem second" onClick={handleCopy}>
            <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
          </div>
        </div>
      </div>

      {/* QR Code Section */}
      <div className="qr-code-section" style={{
          paddingTop: qrCodePaddingTop
        }}>
        <div className="qr-code-container">
          <QRCodeReact.QRCodeSVG 
            value={inviteLink}
            size={qrCodeSize}
            level="H"
            bgColor="transparent"
            fgColor="#FFFFFF" 
            // bgColor="#FFFFFF"
            // fgColor="#000000"
          />
        </div>
      </div>

      {/* Bottom Section - Friends List */}
      <div className="friends_bottom_block">
        <div className="friends_bottom_elem first" onClick={showFriendsDetails}>
          <div className="fb_left__block">
            <div className="fb_icon"></div>
            <div className="fb_text__block">
              <div className="fb_title">
                {userData.language_code === "ru" ? 
                  "МОИ ДРУЗЬЯ" : 
                  "MY FRIENDS"}
              </div>
            </div>
          </div>
          <div className="fb_right__block">
            <div className="fb_friends_amount">{totalFriends}</div>
            <div className="fb_action">
              <svg xmlns="http://www.w3.org/2000/svg"></svg>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Notification */}
      {popup.visible && (
        <div className="popup-modal_wrapper">
        <div className="popup-modal">
            <div className="popup-content">
            <div className="popup-text">
                {popup.text}
            </div>
            <div className="popup-close__button"></div>
            </div>
        </div>
      </div>
      )}

    </div>
      <ModalFriends
        type={screenView}
        userData={userData}
        friends={totalFriends}
        updateView={setScreenView}
        onClose={() => setScreenView('')}
    />
    </>
  );
};

export default FriendsPage;