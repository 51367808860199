import React, { useEffect, useState } from 'react';
import './Loading.css';

interface LoadingProps {
  userData: {
    language_code: string;
  };
}

interface SocialLink {
  network: string;
  wrapperClass: string;
  link: string;
}

const SOCIAL_LINKS: SocialLink[] = [
  {
    network: 'telegram',
    wrapperClass: 'telegram',
    link: 'https://t.me/egggame_official'
  },
  {
    network: 'x',
    wrapperClass: 'x',
    link: 'https://x.com/egggame_official'
  },
  {
    network: 'discord',
    wrapperClass: 'discord',
    link: 'https://discord.gg/egggame'
  },
  {
    network: 'youtube',
    wrapperClass: 'youtube',
    link: 'https://youtube.com/@egggame_official'
  }
];

const LoadingPage: React.FC<LoadingProps> = React.memo(({ userData }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImages = async () => {
      try {
        // Предзагрузка фонового изображения и иконок
        const imagesToLoad = [
          '../../static/loading/loading_bg.webp',
          ...SOCIAL_LINKS.map(link => `../../static/loading/${link.network}_icon.png`)
        ];

        await Promise.all(
          imagesToLoad.map(src => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve;
              img.onerror = reject;
            });
          })
        );
        
        setImagesLoaded(true);
      } catch (error) {
        console.error('Failed to preload images:', error);
        setImagesLoaded(true);
      }
    };

    loadImages();
  }, []);

  const renderSocialLinks = () => (
    <div className="loading_social_elems">
      {SOCIAL_LINKS.map(({ network, wrapperClass, link }) => (
        <div key={network} className={`loading_social_elem_wrap ${wrapperClass}`}>
          <a 
            href={link}
            target="_blank" 
            rel="noopener noreferrer"
            className="loading_social_elem"
            onClick={(e) => {
              e.preventDefault();
              window.open(link, '_blank', 'noopener,noreferrer');
            }}
          >
            <div className="pic" />
          </a>
        </div>
      ))}
    </div>
  );

  if (!imagesLoaded) {
    return (
      <div className="loading__block" style={{ background: '#000' }}>
        <div className="loading__title">
          {userData.language_code === 'ru' ? 'ЗАГРУЗКА' : 'LOADING'}
        </div>
      </div>
    );
  }

  return (
    <div className="loading__block">
      <div className="loading__title">
        THE PROJECT WAS<br/>CREATED ON THE TON<br/>NETWORK
      </div>
      <div className="loading__footer">
        {renderSocialLinks()}
        <div className="loading_text loading">
          {userData.language_code === 'ru' ? 'ЗАГРУЗКА . . .' : 'LOADING . . .'}
        </div>
      </div>
    </div>
  );
});

LoadingPage.displayName = 'LoadingPage';

export default LoadingPage;