import React, { useState, useEffect, Component } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import axios from 'axios';
import { Menu } from "./components/Menu";
import { EGG, Modal, Header, DailyReward, DailyCypher, DailyCombo, Balance, Energy, CHOOSE_EGG } from "./components/EGG/egg_page";
import TASKS from "./components/TASKS/tasks_page";
import { CongratsCoins } from "./components/congrats/congrats_component";
import UPGRADE from "./components/UPGRADE/upgrade_page";
import FRIENDS from "./components/FRIENDS/friends_page";
import START from "./components/START/start_page";
import LoadingPage from "./components/LOADING/loading_page";
import CypherBlock from "./components/CYPHER/cypher_component"
import { BOOST_SCREEN } from "./components/BOOST/boost_component";
import { DailyCountdown } from './components/CountDowns';
import { preloadImages } from './imagePreloader';
import { preloadImagesUPGRADE } from './components/UPGRADE/loader_images';
import SETTINGS_DATA from "./components/settings/settings_component";
import PRIVACY_POLICY from "./components/settings/privacy_policy";
import { AppState } from '../src/types/app.types';
import { TG } from "./telegramContext";
import './css/Placeholder.css';
import "./css/Header.css";
import "./css/Menu.css";
import "./index.css";

export const api = axios.create({
  baseURL: "https://eeggaammee.com",
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});


class App extends Component<{}, AppState> {
  private syncInterval: NodeJS.Timeout | null = null;
  private energyInterval: NodeJS.Timeout | null = null;

  state: AppState = {
    id: 0,
    name: "Unknown",
    balance: 0,
    egg: 0,
    clickValue: 1,
    profitPerHour: 0,
    level: 1,
    screenview: "LOADING",
    additionalView: "",
    upgrades: {},
    DailyCombo: [],
    DailyRewardDay: 0,
    energyValue: 0,
    EnergyPerDelay: 1,
    DelayEnergy: 1000,
    EnergyLimit: 500,
    ComboСurrentReward: 300000,
    tasks: [{ id: "task_x", status: "done" }],
    friends: [],
    dailyRewardModal: false,
    startRewardModal: false,
    is_premium: false,
    last_daily_bonus_collection_timestamp: 0,
    offline_reward: 0,
    language_code: 'en',
    sync_earnings: 0,
    sync: true,
    gratsView: false,
    cypherAvailableView: true,
    loadedImages: [],
    all_upgrades: {},
    isAuthenticated: false,
    last_sync_timestamp: 0,
    photoToUpgrades: [],
    CrazyEnergyUsed: 0,
    all_tasks: [],
    nextCrazyEnergyTimestamp: 0,
  };

  energyAdd = () => {
    if (this.state.energyValue < this.state.EnergyLimit) {
      const toChangeValue = Math.min(
        this.state.energyValue + this.state.EnergyPerDelay,
        this.state.EnergyLimit
      );
      this.setState({ energyValue: toChangeValue });
    }
  };

  updateUserLang = async (lang: string) => {
    try {
      await api.put('/api/user/update', {
        language_code: lang
      });

      this.setState({
        language_code: lang
      });

      TG.WebApp.close();
    } catch (error) {
      console.error('Failed to update language:', error);
    }
  };

  updateUserName = (new_name:string) => {
    this.setState({
      name:new_name
    })
  }

  deleteUser = (res_data:any) => {
    this.updateView("START")
    TG.WebApp.close();
  };

  closeCongrats = () => {
    this.setState({ gratsView: false });
  };

  openCongrats = () => {
    this.setState({ gratsView: true });
  };

  start_func = async (Data: any) => {
    const date = new Date();
    const now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
    const tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

    const userData = Data.user;
    const userStateData = Data.state;

    const CanCypher = Number(now.getTime()) / 1000 >= userData.last_daily_cypher_completed_timestamp;
    const CanCollect = !(Number(now.getTime()) / 1000 <= userData.last_daily_bonus_collection_timestamp && 
                        userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime()) / 1000);

    if (userData.egg === 0) {
      this.setState({ startRewardModal: true });
    }

    if (CanCollect) {
      this.setState({ dailyRewardModal: true });
    }

    if (userData) {
      this.setState({
        id: userData.id,
        name: userData.name,
        balance: userData.balance,
        egg: userData.egg,
        level: userData.level,
        clickValue: userData.click_value,
        profitPerHour: userData.profit_per_hour,
        upgrades: userData.upgrades_lvls,
        DailyCombo: userData.daily_combo_collected,
        DailyRewardDay: userData.daily_bonus_streak_days,
        ComboСurrentReward: userStateData.current_combo_reward,
        CrazyEnergyUsed: userData.crazy_energy_purchased_today_qty,
        energyValue: userData.energy_value,
        EnergyLimit: userData.energy_limit,
        EnergyPerDelay: userData.energy_recovery_per_delay,
        DelayEnergy: userData.DelayEnergy,
        is_premium: userData.is_premium,
        last_daily_bonus_collection_timestamp: userData.last_daily_bonus_collection_timestamp,
        tasks: userData.tasks_statuses,
        offline_reward: userStateData.offline_reward,
        all_tasks: userStateData.tasks,
        all_upgrades: userStateData.upgrades,
        BybitUID: userData.bybit_uid,
        BybitStatus: userData.bybit_status,
        cypherAvailableView: CanCypher,
        language_code: userData.language_code,
        nextCrazyEnergyTimestamp: userData.next_crazy_energy_available_timestamp,
        friends: userData.referrals_lines,
        photoToUpgrades: [],
        loadedImages: [],
        game_stage: userData.stage,
        sync: true
      });

      try {
        await preloadImages();
        const upgradeImages = await preloadImagesUPGRADE(userData.stage);
        this.setState({ 
          screenview: "START",
          photoToUpgrades: upgradeImages
        });
      } catch (error) {
        console.error('Failed to preload images:', error);
      }
    }
  };

  syncSession = async () => {
    if (this.state.sync || this.state.last_sync_timestamp + 5000 > new Date().getTime()) {
      return;
    }

    try {
      await api.post('/api/user/syncSession', {
        balance_increase: this.state.sync_earnings,
        energy_value: this.state.energyValue,
      }).then((res: any) => {
        this.setState({
          game_stage: res.data?.stage,
          sync: true,
          sync_earnings: 0,
          last_sync_timestamp: new Date().getTime()
        });
      })

    } catch (error: any) {
      if (error.response?.status === 401) {
        console.error('Sync failed:', error);
        // TG.WebApp.close();
      } else {
        console.error('Sync failed:', error);
      }
    }
  };

  componentDidMount = async () => {
    TG.WebApp.ready();
    TG.WebApp.disableVerticalSwipes();

    try {
      const initData = TG.WebApp.initData;
      const encodedInitData = encodeURIComponent(initData);
      
      const response = await api.put(`/api/user/initSession?init_data=${encodedInitData}`, {
        ref_id: TG.WebApp.initDataUnsafe.start_param
      });
      
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
      this.setState({ isAuthenticated: true });
      await this.start_func(response.data);
    } catch (error) {
      console.error('Failed to initialize session:', error);
      this.setState({ isAuthenticated: false });
    }

    this.energyInterval = setInterval(this.energyAdd, 1000);
    this.syncInterval = setInterval(this.syncSession, 1000);
  };

  // componentWillUnmount() {
  //   if (this.energyInterval) clearInterval(this.energyInterval);
  //   if (this.syncInterval) clearInterval(this.syncInterval);
  // }

  onEGGidChange = async (egg_id: number) => {
    try {
      await api.put('/api/user/update', { egg: egg_id });
      this.setState({ egg: egg_id });
    } catch (error) {
      console.error('Failed to update EGG:', error);
      this.setState({ egg: egg_id });
    }
  };

  updateView = (view: string) => {
    if (view === "EGG") {
      this.setState({
        screenview: view,
        additionalView: ''
      });
    } else if (view === "FRIENDS") {
      api.get(`/api/user/get`)
        .then(res => {
          this.setState({
            friends: res.data.referrals_lines,
            screenview: view
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({ screenview: view });
        });
    } else {
      this.setState({ screenview: view });
    }
  };


  getDailyReward = async () => {
    api.post('/api/task/verify', {
        "block_id":"block_1",
        "task_id":"task_dailyreward"
      }).then(res => {
        this.setState({
          dailyRewardModal: false,
          balance: res.data.current_balance,
          last_daily_bonus_collection_timestamp: res.data.last_daily_bonus_collection_timestamp,
          DailyRewardDay: res.data.current_daily_bonus_streak_days,
          gratsView: true,
          sync_earnings: 0
        })
      }).catch(err => {
        console.log(err)
        this.setState({dailyRewardModal: false})
    })
  }

  updateAdditionalCypherView = () => {
    if (this.state.additionalView === "CYPHER") {
      this.setState({
        additionalView: ""
       })
    } else {
      this.setState({
        additionalView: "CYPHER"
       })
    }
  }

  doneCypher = (res: any) => {
    if (res === "error") {
      this.setState({
        cypherAvailableView: false,
        additionalView: ""
      })
    } else {
      this.setState({
        balance: res.current_balance,
        cypherAvailableView: false,
        additionalView: "",
        sync_earnings: 0
      })
    }
  }

  clickToAddBalance = () => {
    this.setState({
      sync: false,
      sync_earnings: this.state.sync_earnings+this.state.clickValue,
      balance: this.state.balance+this.state.clickValue,
      energyValue: this.state.energyValue - this.state.clickValue
    })
  }

  buyBoost = async (boostName:string) => {
    if (!this.state.sync) {
      this.syncSession()
    }
    if (boostName === "crazy_energy") {
      if (this.state.EnergyLimit === this.state.energyValue) {
        return
      }
    }

    api.post('/api/store/boost', {
        "boost_type": boostName
    }).then(res => {
      if (boostName === "crazy_energy") {
        this.setState(
          {
            nextCrazyEnergyTimestamp: res.data.next_crazy_energy_available_timestamp,
            CrazyEnergyUsed: res.data.crazy_energy_purchased_today_qty,
            balance: res.data.balance,
            clickValue: res.data.click_value,
            energyValue: res.data.energy_limit,
            EnergyLimit: res.data.energy_limit,
            EnergyPerDelay: res.data.energy_recovery_per_delay,
            sync_earnings: 0
          })
      } else {
        this.setState(
          {
            balance: res.data.balance,
            clickValue: res.data.click_value,
            EnergyLimit: res.data.energy_limit,
            EnergyPerDelay: res.data.energy_recovery_per_delay,
            sync_earnings: 0
          }
        )
      }
    }).catch(err => {

    })
  }

  getOfflineReward = () => {
    this.setState({
      offline_reward: 0
     })
  }

  getStartReward = () => {
    this.setState({
      startRewardModal: false
     })
  }

  addTaskDone = (balance:number, tasks_statuses:any) => {
    this.setState({
      tasks: tasks_statuses,
      balance: balance,
      sync_earnings: 0
    })
  }

  addBybit = (bybit_id:string, res_data: any) => {
    this.setState({
      tasks: res_data.tasks_statuses,
      BybitUID: bybit_id,
      sync_earnings: 0
    })
  }

  buyUpgrade = (res: any) => {
    this.setState({
      balance: res.data.balance,
      upgrades: res.data.upgrades_lvls,
      DailyCombo: res.data.daily_combo_collected,
      profitPerHour: res.data.profit_per_hour,
      sync_earnings: 0
      })
  }

  onBalanceChange = (newBalance: any) => {
    this.setState({
        balance: newBalance,
        sync: false,
        sync_earnings: newBalance - this.state.balance + this.state.sync_earnings,
      });
  }

  
  render() {
    if (!this.state.isAuthenticated) {
      return <LoadingPage userData={this.state} />;
    }

    switch (this.state.screenview) {
      case 'PRIVACY_POLICY':
        return (
          <main>
            <PRIVACY_POLICY 
              userData={this.state} 
              updateView={this.updateView}
            />
            <Menu updateView={this.updateView} activeScreen={''} />
          </main>
        );

      case 'SETTINGS':
        return (
          <main>
            <SETTINGS_DATA 
              userData={this.state} 
              updateUserLang={this.updateUserLang}
              updateView={this.updateView}
              updateUserName={this.updateUserName}
              deleteUser={this.deleteUser}
            />
            <Menu updateView={this.updateView} activeScreen={''} />
          </main>
        );

      case 'LOADING':
        return (
          <main>
            <LoadingPage userData={this.state} />
          </main>
        );

      case 'START':
        return (
          <main>
            <START 
              updateView={this.updateView} 
              userData={this.state}
            />
          </main>
        );

      case 'EGG':
        if (this.state.egg !== 0) {
          return (
            <main>
              <Header 
                name={this.state.name}
                level={this.state.level}
                profit={this.state.profitPerHour}
                userData={this.state}
                updateView={this.updateView}
              />
              <div className="daily_block">
                <DailyReward 
                  userData={this.state}
                  getDailyReward={this.getDailyReward}
                />
                <DailyCypher 
                  action={this.updateAdditionalCypherView}
                  availableView={this.state.cypherAvailableView}
                  userData={this.state}
                />
                <DailyCombo 
                  updateView={this.updateView}
                  countdown={<DailyCountdown />}
                  userData={this.state}
                />
              </div>
              <div className="egg_balance__block">
                {this.state.additionalView === 'CYPHER' ? (
                  <CypherBlock 
                    userData={this.state}
                    doneCypher={this.doneCypher}
                    openCongrats={this.openCongrats}
                  />
                ) : (
                  <Balance value={this.state.balance} profitPerHour={this.state.profitPerHour} onBalanceChange={this.onBalanceChange}
                />
                )}
              </div>
              <EGG 
                egg={this.state.egg}
                energyEnough={this.state.energyValue >= this.state.clickValue}
                canIClickPlease={true}
                sleep={false}
                funMode={false}
                userData={this.state}
                Mode={this.state.additionalView}
                clickValue={this.state.clickValue}
                cooldown={0}
                handleClick={this.clickToAddBalance}
              />
              <div className="above_menu__block">
                <Energy
                  value={this.state.energyValue}
                  limit={this.state.EnergyLimit}
                />
                <BOOST_SCREEN 
                  userData={this.state}
                  updateUser={this.buyBoost}
                  dailyCounter={<DailyCountdown />}
                />
              </div>
              <Menu updateView={this.updateView} activeScreen={'EGG'} />

              <Modal 
                type="OfflineEarnings"
                visible={this.state.offline_reward > 0}
                userData={this.state}
                onClose={this.getOfflineReward}
                updateUser={this.getOfflineReward}
              />

              <Modal 
                type="StartReward"
                visible={this.state.startRewardModal}
                userData={this.state}
                onClose={this.getStartReward}
                updateUser={this.getStartReward}
              />
              
              {!this.state.startRewardModal && (
                <Modal
                  type="DailyReward"
                  visible={this.state.dailyRewardModal}
                  userData={this.state}
                  onClose={this.getDailyReward}
                  updateUser={this.getDailyReward}
                />
              )}
              <CongratsCoins 
                visible={this.state.gratsView} 
                onClose={this.closeCongrats}
              />
            </main>
          );
        }
        return (
          <main className="start">
            <CHOOSE_EGG 
              onEGGidChange={this.onEGGidChange}
              userData={this.state}
            />
          </main>
        );

      case 'BATTLES':
        return (
          <main>
            <div className="placeholder__block">
              <div className="placeholder__text">
                {this.state.language_code === "ru" 
                  ? "Скоро откроется"
                  : "Will be launched soon"}
              </div>
            </div>
            <Menu updateView={this.updateView} activeScreen={'BATTLES'} />
          </main>
        );

      case 'FRIENDS':
        return (
          <main>
            <Header 
              name={this.state.name}
              level={this.state.level}
              profit={this.state.profitPerHour}
              userData={this.state}
              updateView={this.updateView}
            />
            <FRIENDS 
              userData={this.state}
              updateView={this.updateView}
            />
            <Menu updateView={this.updateView} activeScreen={'FRIENDS'} />
          </main>
        );

      case 'TASKS':
        return (
          <main>
            <Header 
              name={this.state.name}
              level={this.state.level}
              profit={this.state.profitPerHour}
              userData={this.state}
              updateView={this.updateView}
            />
            <TASKS 
              userData={this.state}
              addTaskDone={this.addTaskDone}
              addBybit={this.addBybit}
              openCongrats={this.openCongrats}
            />
            <Menu updateView={this.updateView} activeScreen={'TASKS'} />
            <CongratsCoins 
              visible={this.state.gratsView}
              onClose={this.closeCongrats}
            />
          </main>
        );

      case 'UPGRADE':
        return (
          <main>
            <Header
              name={this.state.name}
              level={this.state.level}
              profit={this.state.profitPerHour}
              userData={this.state}
              updateView={this.updateView}
            />
            <UPGRADE
              balance={this.state.balance}
              onBalanceChange={this.onBalanceChange}
              userData={this.state}
              buyUpgrade={this.buyUpgrade}
              syncSession={this.syncSession}
              openCongrats={this.openCongrats}
              photoToUpgrades={this.state.photoToUpgrades}
            />
            <Menu updateView={this.updateView} activeScreen={'UPGRADE'} />
            <CongratsCoins
              visible={this.state.gratsView}
              onClose={this.closeCongrats}
            />
          </main>
        );

      case 'WALLET':
      default:
        return (
          <main>
            <div className="placeholder__block">
              <div className="placeholder__text">
                {this.state.language_code === "ru" 
                  ? "Скоро откроется"
                  : "Will be launched soon"}
              </div>
            </div>
            <Menu updateView={this.updateView} activeScreen={'WALLET'} />
          </main>
        );
    }
  }
}

export default App;