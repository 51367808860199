import MU1 from '../../static/upgrade/MU1.png'
import MU2 from '../../static/upgrade/MU2.png'
import MU3 from '../../static/upgrade/MU3.png'
import MU4 from '../../static/upgrade/MU4.png'
import MU5 from '../../static/upgrade/MU5.png'
import MU6 from '../../static/upgrade/MU6.png'
import MU7 from '../../static/upgrade/MU7.png'
import MU8 from '../../static/upgrade/MU8.png'
import MU9 from '../../static/upgrade/MU9.png'
import MU10 from '../../static/upgrade/MU10.png'
import MU11 from '../../static/upgrade/MU11.png'

import MU12 from '../../static/upgrade/MU12.png'
import MU13 from '../../static/upgrade/MU13.png'
import MU14 from '../../static/upgrade/MU14.png'
import MU15 from '../../static/upgrade/MU15.png'
import MU16 from '../../static/upgrade/MU16.png'
import MU17 from '../../static/upgrade/MU17.png'
import MU18 from '../../static/upgrade/MU18.png'
import MU19 from '../../static/upgrade/MU19.png'
import MU20 from '../../static/upgrade/MU20.png'
import MU21 from '../../static/upgrade/MU21.png'

import CU1 from '../../static/upgrade/CU1.png'
import CU2 from '../../static/upgrade/CU2.png'
import CU3 from '../../static/upgrade/CU3.png'
import CU4 from '../../static/upgrade/CU4.png'
import CU5 from '../../static/upgrade/CU5.png'
import CU6 from '../../static/upgrade/CU6.png'
import CU7 from '../../static/upgrade/CU7.png'
import CU8 from '../../static/upgrade/CU8.png'
import CU9 from '../../static/upgrade/CU9.png'
import CU10 from '../../static/upgrade/CU10.png'

import unknown_combo from '../../static/upgrade/unknown_combo.png'


export const stage1 = [
  MU1,
  MU2,
  MU3,
  MU4,
  MU5,
  MU6,
  MU7,
  MU8,
  MU9,
  MU10,
  MU11,
  CU1, 
  CU2, 
  CU3,
  CU4,
  CU5,
  CU6,
  CU7,
  CU8,
  CU9,
  CU10,
  unknown_combo
]

export const stage2 = [
  MU12,
  MU13,
  MU14,
  MU15,
  MU16,
  MU17,
  MU18,
  MU19,
  MU20,
  MU21
]

export const stage3 = []

export const stage4 = []

export function preloadImage (src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = function() {
        resolve(img)
      }
      img.onerror = img.onabort = function() {
        reject(src)
      }
      img.src = src
    })
  }
  
  
  export const preloadImagesUPGRADE = (stage) => {
      const preloadSrcList = []
      if (stage >= 1) {
        // preloadSrcList.set("1", stage1)
        preloadSrcList.push(...stage1)
        // preloadSrcList += stage1
      } 
      
      if (stage >= 2) {
        // preloadSrcList.set("2", stage2)
        preloadSrcList.push(...stage2)
        // preloadSrcList += stage2
      } 
      
      if (stage >= 3) {
        // preloadSrcList.set("3", stage3)
        preloadSrcList.push(...stage3)
        // preloadSrcList += stage3
      } 
      
      if (stage >= 4) {
        // preloadSrcList.set("4", stage4)
        preloadSrcList.push(...stage4)
        // preloadSrcList += stage4
      }

      const promises = preloadSrcList.map((src) => preloadImage(src));
      return Promise.all(promises);
    };
