// Создаем кэш для изображений
const IMAGE_CACHE = new Map();

// Группируем изображения по приоритету и категориям
const CRITICAL_IMAGES = {
  LOADING: [
    require('./static/loading/loading_bg.webp'),
    require('./static/coin.webp'),
    require('./static/start/start_bg.png')
  ],
  CORE_EGGS: [
    require('./static/eggs/base/pink.webp'),
    require('./static/eggs/base/blue.webp'),
    require('./static/eggs/base/green.webp')
  ],
  CORE_UI: [
    require('./static/boost/boosts.webp'),
    require('./static/menu/mining_icon.svg'),
    require('./static/menu/battles_icon.svg')
  ]
};

const SECONDARY_IMAGES = {
  REMAINING_EGGS: [
    require('./static/eggs/base/black.webp'),
    require('./static/eggs/base/orange.webp'),
    require('./static/eggs/base/purple.webp'),
    require('./static/eggs/base/red.webp'),
    require('./static/eggs/base/white.webp'),
    require('./static/eggs/base/tiffany.webp'),
    require('./static/eggs/base/gold.webp')
  ],
  MENU_ICONS: [
    require('./static/menu/friends_icon.svg'),
    require('./static/menu/improvements_icon.svg'),
    require('./static/menu/wallet_icon.svg'),
    require('./static/menu/assignments_icon.svg')
  ],
  TASK_ICONS: [
    require('./static/tasks/task_action_done_icon.svg'),
    require('./static/tasks/task_action_ready_icon.svg'),
    require('./static/tasks/task_bybit.png'),
    require('./static/tasks/task_daily.png'),
    require('./static/tasks/task_discord.png'),
    require('./static/tasks/task_guess.png'),
    require('./static/tasks/task_telegram.png'),
    require('./static/tasks/task_x.png'),
    require('./static/tasks/task_youtube.png'),
    require('./static/tasks/tasks_icon.png'),
    require('./static/tasks/daily_cypher_icon.webp'),
    require('./static/tasks/daily_combo_icon.webp')
  ]
};

const DEFERRED_IMAGES = {
  BOOST_ICONS: [
    require('./static/boost/crazy.png'),
    require('./static/boost/energy_limit.png'),
    require('./static/boost/energy_speed.png'),
    require('./static/boost/multitap.png')
  ],
  FRIEND_IMAGES: [
    require('./static/friends/friends.webp'),
    require('./static/friends/friends_empty.webp')
  ],
  SOCIAL_ICONS: [
    require('./static/loading/telegram_icon.png'),
    require('./static/loading/discord_icon.png'),
    require('./static/loading/x_icon.png'),
    require('./static/loading/youtube_icon.png'),
    require('./static/loading/star_icon.png'),
    require('./static/loading/plus_icon.png'),
    require('./static/loading/copy_icon.png')
  ],
  START_IMAGES: [
    require('./static/start/first.webp'),
    require('./static/start/second.webp'),
    require('./static/start/third.webp'),
    require('./static/start/fourth.webp'),
    require('./static/start/fifth.webp'),
    require('./static/start/sixth.webp')
  ]
};

// Функция загрузки одного изображения с кэшированием
function loadSingleImage(src) {
  if (IMAGE_CACHE.has(src)) {
    return Promise.resolve(IMAGE_CACHE.get(src));
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    
    const timeoutId = setTimeout(() => {
      reject(new Error(`Timeout loading image: ${src}`));
    }, 10000); // 10 секунд таймаут

    img.onload = () => {
      clearTimeout(timeoutId);
      IMAGE_CACHE.set(src, img);
      resolve(img);
    };

    img.onerror = () => {
      clearTimeout(timeoutId);
      reject(new Error(`Failed to load image: ${src}`));
    };

    img.src = src;
  });
}

// Функция загрузки группы изображений
async function loadImageGroup(images, options = {}) {
  const { signal, onProgress, concurrency = 4 } = options;
  const total = images.length;
  let loaded = 0;

  // Загружаем изображения группами по concurrency штук
  const chunks = [];
  for (let i = 0; i < images.length; i += concurrency) {
    chunks.push(images.slice(i, i + concurrency));
  }

  for (const chunk of chunks) {
    if (signal?.aborted) {
      break;
    }

    await Promise.all(
      chunk.map(src => 
        loadSingleImage(src)
          .then(img => {
            loaded++;
            onProgress?.(loaded / total);
            return img;
          })
          .catch(error => {
            console.warn(error);
            return null;
          })
      )
    );
  }
}

// Функция подсчета общего количества изображений в группе
function countImagesInGroup(group) {
  return Object.values(group).reduce((acc, arr) => acc + arr.length, 0);
}

// Основная функция загрузки с приоритетами
export async function preloadImages(options = {}) {
  const { 
    onProgress, 
    onCriticalLoad, 
    onSecondaryLoad,
    signal 
  } = options;

  const totalCritical = countImagesInGroup(CRITICAL_IMAGES);
  const totalSecondary = countImagesInGroup(SECONDARY_IMAGES);
  const totalDeferred = countImagesInGroup(DEFERRED_IMAGES);
  const total = totalCritical + totalSecondary + totalDeferred;

  let loadedCount = 0;

  try {
    // Загружаем критические изображения
    const criticalGroups = Object.values(CRITICAL_IMAGES);
    for (const group of criticalGroups) {
      await loadImageGroup(group, { 
        signal,
        concurrency: 6, // Повышенный параллелизм для критичных ресурсов
        onProgress: (progress) => {
          loadedCount = progress * totalCritical;
          onProgress?.(loadedCount / total);
        }
      });
    }
    onCriticalLoad?.();

    // Загружаем вторичные изображения
    const secondaryGroups = Object.values(SECONDARY_IMAGES);
    for (const group of secondaryGroups) {
      await loadImageGroup(group, {
        signal,
        concurrency: 4,
        onProgress: (progress) => {
          const secondaryLoaded = progress * totalSecondary;
          onProgress?.((loadedCount + secondaryLoaded) / total);
        }
      });
    }
    onSecondaryLoad?.();

    // Загружаем отложенные изображения
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => {
        const deferredGroups = Object.values(DEFERRED_IMAGES);
        deferredGroups.forEach(group => {
          loadImageGroup(group, {
            signal,
            concurrency: 2, // Пониженный параллелизм для отложенной загрузки
            onProgress: (progress) => {
              const deferredLoaded = progress * totalDeferred;
              onProgress?.((loadedCount + totalSecondary + deferredLoaded) / total);
            }
          });
        });
      }, { timeout: 1000 });
    }

    return IMAGE_CACHE;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Image loading was aborted');
    } else {
      console.error('Error in preloadImages:', error);
    }
    throw error;
  }
}

// Вспомогательные функции
export function getImageFromCache(src) {
  return IMAGE_CACHE.get(src);
}

export function clearImageCache() {
  IMAGE_CACHE.clear();
}

export function isImageCached(src) {
  return IMAGE_CACHE.has(src);
}

export function getCacheStats() {
  return {
    totalCached: IMAGE_CACHE.size,
    memoryUsageEstimate: Array.from(IMAGE_CACHE.values()).reduce((acc, img) => {
      return acc + (img.width * img.height * 4);
    }, 0)
  };
}